import React, { createContext, useCallback, useState } from "react";
import PropTypes from "prop-types";
const defaultContext = {
  companySizeList: [],
  industryList: [],
  isLoadError: false,
  onChangeFormListOptions: (_name = "", _values = []) => {},
  roleList: [],
  setIsLoadError: (_isError) => {},
};
const ProfileFormListOptionsContext = createContext(defaultContext);

const ProfileFormListOptionsContextProvider = ({ children }) => {
  const [companySizeList, setCompanySizeList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [roleList, setRoleList] = useState([]);

  const [isLoadError, setIsLoadError] = useState(false);

  const onChangeFormListOptions = useCallback((name = "", value = []) => {
    switch (name) {
      case "companySizeList":
        setCompanySizeList(value || []);
        break;
      case "industryList":
        setIndustryList(value || []);
        break;
      case "roleList":
        setRoleList(value || []);
        break;
      default:
        console.warn("unable to set list due to unknown name", name);
    }
  }, []);

  const state = {
    companySizeList,
    industryList,
    isLoadError,
    onChangeFormListOptions,
    roleList,
    setIsLoadError,
  };
  return <ProfileFormListOptionsContext.Provider value={state}>{children}</ProfileFormListOptionsContext.Provider>;
};
ProfileFormListOptionsContextProvider.propTypes = {
  children: PropTypes.any, // issue with children propType being node when using in TS components
};

export { ProfileFormListOptionsContext, ProfileFormListOptionsContextProvider };
