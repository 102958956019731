import { colorPalette, weightPalette } from "gx-npm-common-styles";

const browsingReasonButtonStyle = {
  button: {
    background: colorPalette.neutrals.pearl.hex,
    border: "none",
    cursor: "pointer",
    height: "128px",
    position: "relative",
    width: "290px",
    "&.selected": {
      border: `2px solid ${colorPalette.interactions.defaultCta.hex}`,
    },
    "&.hovered, &.selected": {
      background: colorPalette.basic.white.hex,
    },
    "&.hovered:not(.selected)": {
      boxShadow: `0 0 20px ${colorPalette.neutrals.carbon.hex}33`,
    },
  },

  image: {
    position: "absolute",
    bottom: "0",
    right: "0",
  },
  checkbox: {
    position: "absolute",
    right: "10px",
    top: "10px",
  },
  text: {
    color: colorPalette.neutrals.carbon.hex,
    fontSize: "14px",
    fontVariationSettings: weightPalette.semiBold.variation,
    fontWeight: weightPalette.semiBold.amount,
    letterSpacing: "0.25px",
    lineHeight: "21px",
    marginLeft: "20px",
    marginTop: "16px",
    width: "200px",
  },
};

export default browsingReasonButtonStyle;
