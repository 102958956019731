const appV2Styles = {
  "@global": {
    ".Profile-App-MuiDialog-root": {
      transition: "opacity 0.35s",
      "&.gx-closing": {
        opacity: "0 !important",
      },
      "&.gx-profile-v2-dialog .gx-dialog-footer": {
        marginTop: "auto",
        position: "absolute",
        bottom: "44px",
        width: "89%",
      },
    },
    ".Profile-App-MuiBackdrop-root": {
      backdropFilter: "blur(6px)",
    },
    ".Profile-App-MuiPaper-root.Profile-App-MuiDialog-paper": {
      "& > div:first-child": {
        height: "100%",
        position: "relative",
      },
      maxWidth: "700px",
      height: "640px",
      "& .gx-dialog-body": {
        height: "76%",
        position: "relative",
        overflowY: "auto",
      },
    },
  },
};

export default appV2Styles;
