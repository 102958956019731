import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import styles from "./browsing-reason-button.style";
import CheckmarkCompleteIcon from "./checkmark-complete.icon";
import CheckmarkIncompleteIcon from "./checkmark-incomplete.icon";

const propTypes = {
  image: PropTypes.any,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  rootClassName: PropTypes.string,
  text: PropTypes.string,
};
const useStyles = makeStyles(() => styles);
const BrowsingReasonButton = ({
  image = null,
  isSelected = false,
  onClick = () => {},
  rootClassName = "",
  text = "",
}) => {
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={classNames(classes.button, rootClassName, isHovered && "hovered", isSelected && "selected")}
      onClick={onClick}
      onKeyDown={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      role="button"
      tabIndex={0}
    >
      <p className={classNames(classes.text)}>{text}</p>
      <div className={classNames(classes.checkbox)}>
        {isSelected && <CheckmarkCompleteIcon />}
        {!isSelected && <CheckmarkIncompleteIcon />}
      </div>
      {image && <img alt="" className={classNames(classes.image)} src={image} />}
    </div>
  );
};

BrowsingReasonButton.propTypes = propTypes;
export default BrowsingReasonButton;
