import { useContext, useEffect } from "react";
import { ProfileFormListOptionsContext } from "./profile-form-list-options.context";
import { getProfileMenus } from "../lib/profile-data-requests.lib";

const useFormListOptions = () => {
  const { companySizeList, industryList, isLoadError, onChangeFormListOptions, roleList, setIsLoadError } =
    useContext(ProfileFormListOptionsContext);

  useEffect(() => {
    getProfileMenus(
      {},
      (menus) => {
        onChangeFormListOptions("companySizeList", menus.companySizeList || []);
        onChangeFormListOptions("industryList", menus.industryList || []);
        onChangeFormListOptions("roleList", menus.roleList || []);
      },
      () => {
        setIsLoadError(true);
      }
    );
  }, [onChangeFormListOptions, setIsLoadError]);

  return { companySizeList, industryList, isLoadError, roleList };
};

export default useFormListOptions;
