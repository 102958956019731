import { getAsyncRequest, isValidResponse, postAsyncRequest } from "gx-npm-lib";

const getProfileMenus = async (_data = {}, onSuccess = (_menus) => {}, onError = () => {}, onComplete = () => {}) => {
  const response = await getAsyncRequest("/api/v2/data/profile");
  if (isValidResponse(response) && !!response.data.data && typeof response.data.data === "object") {
    onSuccess(response.data.data);
  } else {
    onError();
  }
  onComplete();
};

const getUserProfile = async (_data = {}, onSuccess = (_profile) => {}, onError = () => {}, onComplete = () => {}) => {
  const response = await getAsyncRequest("/api/v2/user/profile");
  if (isValidResponse(response) && !!response.data.data && typeof response.data.data === "object") {
    onSuccess(response.data.data);
  } else {
    onError();
  }
  onComplete();
};

const saveUserProfile = async (data = {}, onSuccess = () => {}, onError = () => {}, onComplete = () => {}) => {
  const config = { headers: { "Content-Type": "application/json" } };
  const response = await postAsyncRequest("/api/v2/user/profile", data, config);
  if (isValidResponse(response)) {
    onSuccess();
  } else {
    onError();
  }
  onComplete();
};

export { getProfileMenus, getUserProfile, saveUserProfile };
