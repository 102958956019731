import { useContext, useEffect } from "react";
import { ProfileFormAnswersContext } from "./profile-form-answers.context";
import { getUserProfile } from "../lib/profile-data-requests.lib";

/**
 * @event getUserProfile get request for existing user profile data
 * @fires getUserProfile everywhere hook is invoked, only use in a single spot to avoid duplicate calls
 */
const useProfileFormAnswers = () => {
  const { browsingReason, companySize, industry, isFormComplete, onChangeFormAnswer, role, title } =
    useContext(ProfileFormAnswersContext);

  useEffect(() => {
    getUserProfile({}, (profile) => {
      onChangeFormAnswer("browsingReason", profile.browsingReason || "");
      onChangeFormAnswer("companySize", profile.companySize || "");
      onChangeFormAnswer("industry", profile.industry || "");
      onChangeFormAnswer("role", profile.role || "");
      onChangeFormAnswer("title", profile.title || "");
    });
  }, [onChangeFormAnswer]);

  return { browsingReason, companySize, industry, isFormComplete, onChangeFormAnswer, role, title };
};

export default useProfileFormAnswers;
