import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { Button } from "gx-npm-ui";
import useProfileFormSubmission from "../../context/use-profile-form-submission.hook";
import styles from "./submit-button-v2.styles";

const useStyles = makeStyles(() => styles);
const SubmitButtonV2 = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isFormComplete, isSaving, isSaveSuccess, onSubmitForm } = useProfileFormSubmission();

  return (
    <div className={classNames(classes.footerContainer)}>
      <p className="gx-required">* {t("required")}</p>
      <Button
        disabled={!isFormComplete || isSaving || isSaveSuccess}
        onClick={onSubmitForm}
        rootClassName="btn-primary gx-profile-submit-button"
      >
        {t("Continue")}
      </Button>
    </div>
  );
};

export default SubmitButtonV2;
