import React, { createContext, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
const defaultContext = {
  browsingReason: "",
  companySize: "",
  industry: "",
  isFormComplete: false,
  isSaveError: false,
  isSaveSuccess: false,
  isSaving: false,
  onChangeFormAnswer: (_name = "", _value = "") => {},
  onSubmitForm: () => {},
  role: "",
  setIsSaveError: (_isError) => {},
  title: "",
};
const ProfileFormAnswersContext = createContext(defaultContext);

const ProfileFormAnswersContextProvider = ({ children }) => {
  const [browsingReason, setBrowsingReason] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [industry, setIndustry] = useState("");
  const [role, setRole] = useState("");
  const [title, setTitle] = useState("");

  const [isFormComplete, setIsFormComplete] = useState(false);
  const [isSaveError, setIsSaveError] = useState(false);
  const [isSaveSuccess, setIsSaveSuccess] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const onChangeFormAnswer = useCallback((name = "", value = "") => {
    switch (name) {
      case "browsingReason":
        setBrowsingReason(value);
        break;
      case "companySize":
        setCompanySize(value);
        break;
      case "industry":
        setIndustry(value);
        break;
      case "role":
        setRole(value);
        break;
      case "title":
        setTitle(value);
        break;
      default:
        console.warn("unable to set answer due to unknown name", name);
    }
  }, []);

  useEffect(() => {
    setIsFormComplete(!!companySize && !!industry && !!role && !!title);
  }, [companySize, industry, role, title]);

  const state = {
    browsingReason,
    companySize,
    industry,
    isFormComplete,
    isSaveError,
    isSaveSuccess,
    isSaving,
    onChangeFormAnswer,
    role,
    setIsSaveError,
    setIsSaveSuccess,
    setIsSaving,
    title,
  };
  return <ProfileFormAnswersContext.Provider value={state}>{children}</ProfileFormAnswersContext.Provider>;
};
ProfileFormAnswersContextProvider.propTypes = {
  children: PropTypes.any, // issue with children propType being node when using in TS components
};
export { ProfileFormAnswersContext, ProfileFormAnswersContextProvider };
