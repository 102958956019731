const newDefaultRedirectURL = "/s/markets";
const redirectURLFromWindowLocation = () => {
  if (!window?.location || typeof window.location !== "object") {
    return newDefaultRedirectURL;
  }
  const { hash, pathname, search } = window.location;
  if (hash !== "#/profile" || !pathname) {
    return newDefaultRedirectURL;
  }
  return `${pathname}${search || ""}`;
};

export default redirectURLFromWindowLocation;
