import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Select,
  TextField,
  TypographyComponent,
} from "gx-npm-ui";
import ThumbsUpHandSVG from "../../assets/thumbs-up-hand.svg";
import FakeLogosSVG from "../../assets/fake-logos.svg";
import useFormAnswers from "../../context/use-profile-form-answers.hook";
import useFormListOptions from "../../context/use-profile-form-list-options.hook";
import BrowsingReasonButton from "./browsing-reason-button/browsing-reason-button.component";
import styles from "./profile-form.styles";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flags";

const useStyles = makeStyles(() => styles);
const ProfileForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { browsingReason, companySize, industry, onChangeFormAnswer, role, title } = useFormAnswers();
  const { companySizeList, industryList, roleList } = useFormListOptions();

  const handleClick = (browsingOption = "") => {
    const value = browsingOption === browsingReason ? "" : browsingOption;
    onChangeFormAnswer("browsingReason", value);
  };

  return (
    <div className={classNames(classes.formContainer)}>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent
            styling={"p2"}
            color={"coal"}
            boldness={"regular"}
            rootClassName={classNames(classes.GCOM3606subtitle)}
          >
            {t("To personalize your experience, tell us a little bit about what kind of work you do.")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <p className={classNames(classes.subtitle)}>
            {t("To personalize your experience, tell us a little bit about what kind of work you do.")}
          </p>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <div className={classNames(classes.formRow)}>
        <Select
          disabled={roleList.length === 0}
          items={roleList}
          label={`${t("Role")}*`}
          menuHeight="210px"
          onChange={(event) => onChangeFormAnswer("role", event.target.value)}
          popOverPaperClassName="gx-profile-select-paper-popover"
          rootClassName="gx-profile-input"
          value={role}
        />
        <TextField
          label={`${t("Title")}*`}
          onChange={(event) => onChangeFormAnswer("title", event.target.value)}
          rootClassName="gx-profile-input"
          value={title}
        />
      </div>
      <div className={classNames(classes.formRow)}>
        <Select
          disabled={companySizeList.length === 0}
          items={companySizeList}
          label={`${t("Company size")}*`}
          menuHeight="210px"
          onChange={(event) => onChangeFormAnswer("companySize", event.target.value)}
          popOverPaperClassName="gx-profile-select-paper-popover"
          rootClassName="gx-profile-input"
          value={companySize}
        />
        <Select
          disabled={industryList.length === 0}
          items={industryList}
          label={`${t("Industry")}*`}
          menuHeight="210px"
          onChange={(event) => onChangeFormAnswer("industry", event.target.value)}
          popOverPaperClassName="gx-profile-select-paper-popover"
          rootClassName="gx-profile-input"
          value={industry}
        />
      </div>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent
            styling={"p2"}
            color={"coal"}
            boldness={"regular"}
            rootClassName={classNames(classes.GCOM3606subtitle, classes.cardsSubtitle)}
          >
            {t("What brings you here today?")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <p className={classNames(classes.subtitle, classes.cardsSubtitle)}>{t("What brings you here today?")}</p>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <div className={classNames(classes.formRow)}>
        <BrowsingReasonButton
          image={ThumbsUpHandSVG}
          isSelected={browsingReason === "READY_TO_START"}
          onClick={() => handleClick("READY_TO_START")}
          text={t("I have a tech evaluation I’m ready to start / I’ve been invited to an initiative.")}
        />
        <BrowsingReasonButton
          image={FakeLogosSVG}
          isSelected={browsingReason === "JUST_BROWSING"}
          onClick={() => handleClick("JUST_BROWSING")}
          rootClassName="gx-second-browsing-button"
          text={t("I'm just browsing.")}
        />
      </div>
    </div>
  );
};

export default ProfileForm;
