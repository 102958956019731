import { useContext } from "react";
import { publish } from "gx-npm-messenger-util";
import { ProfileFormAnswersContext } from "./profile-form-answers.context";
import { saveUserProfile } from "../lib/profile-data-requests.lib";
import broadcastReloadIntercomStateMessage from "../lib/broadcast-reload-intercom-state-message.lib";
import closeProfileWithRedirectTransition from "../lib/close-profile-with-redirect-transition.lib";
import broadcastCompleteProfileMessage from "../lib/broadcast-complete-profile-message.lib";

const useProfileFormSubmission = () => {
  const {
    browsingReason,
    companySize,
    industry,
    isFormComplete,
    isSaveError,
    isSaveSuccess,
    isSaving,
    role,
    setIsSaveError,
    setIsSaveSuccess,
    setIsSaving,
    title,
  } = useContext(ProfileFormAnswersContext);

  const onSubmitForm = () => {
    setIsSaving(true);
    saveUserProfile(
      { browsingReason, companySize, industry, role, title },
      () => {
        setIsSaveSuccess(true);
        publish("USER_STATE_LOAD");
        broadcastCompleteProfileMessage();
        broadcastReloadIntercomStateMessage();
        closeProfileWithRedirectTransition();
      },
      () => setIsSaveError(true),
      () => setIsSaving(false)
    );
  };

  return { isFormComplete, isSaveError, isSaveSuccess, isSaving, onSubmitForm, setIsSaveError };
};

export default useProfileFormSubmission;
