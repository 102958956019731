import React from "react";
import { createGenerateClassName, createMuiTheme, StylesProvider, ThemeProvider } from "@material-ui/core";
import { commonTheme } from "gx-npm-common-styles";
import { FeatureFlagProvider } from "gx-npm-ui";
import { messengerFlagLoader } from "gx-npm-lib";
import AppV2 from "./app-v2.component";
import { ProfileFormAnswersContextProvider } from "./context/profile-form-answers.context";
import { ProfileFormListOptionsContextProvider } from "./context/profile-form-list-options.context";

const generateClassName = createGenerateClassName({
  seed: "Profile-App",
});
const muiTheme = createMuiTheme(commonTheme);
export default function Root() {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <ThemeProvider theme={muiTheme}>
        <StylesProvider generateClassName={generateClassName}>
          <ProfileFormAnswersContextProvider>
            <ProfileFormListOptionsContextProvider>
              <AppV2 />
            </ProfileFormListOptionsContextProvider>
          </ProfileFormAnswersContextProvider>
        </StylesProvider>
      </ThemeProvider>
    </FeatureFlagProvider>
  );
}
