import { colorPalette, weightPalette } from "gx-npm-common-styles";

const submitButtonV2Styles = {
  footerContainer: {
    display: "flex",
    "& .gx-required": {
      alignItems: "end",
      color: colorPalette.neutrals.coal.hex,
      display: "inline-flex",
      // p4 styling
      fontSize: "12px",
      fontVariationSettings: weightPalette.regular.variation,
      fontWeight: weightPalette.regular.amount,
      letterSpacing: "0.35px",
      lineHeight: "18px",
    },
    "& .btn-primary.gx-profile-submit-button": {
      display: "inline-flex",
      marginLeft: "auto",
      marginRight: "0",
      width: "auto",
    },
  },
};

export default submitButtonV2Styles;
