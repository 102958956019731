import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import {
  Button,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import useProfileFormSubmission from "../../context/use-profile-form-submission.hook";
import styles from "./submit-button-v2.styles";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flags";

const useStyles = makeStyles(() => styles);
const SubmitButtonV2 = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isFormComplete, isSaving, isSaveSuccess, onSubmitForm } = useProfileFormSubmission();

  return (
    <div className={classNames(classes.footerContainer)}>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent styling={"p4"} boldness={"regular"} color={"coal"} rootClassName="GCOM3606gx-required">
            * {t("required")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <p className="gx-required">* {t("required")}</p>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <Button
        disabled={!isFormComplete || isSaving || isSaveSuccess}
        onClick={onSubmitForm}
        rootClassName="btn-primary gx-profile-submit-button"
      >
        {t("Continue")}
      </Button>
    </div>
  );
};

export default SubmitButtonV2;
