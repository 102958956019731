import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { Dialog, SnackbarBanner } from "gx-npm-ui";
import ProfileForm from "./sections/body/profile-form.component";
import SubmitButtonV2 from "./sections/footer/submit-button-v2.component";
import styles from "./app-v2.styles";
import useProfileFormSubmission from "./context/use-profile-form-submission.hook";
import useFormListOptionsRequestErrors from "./context/use-profile-form-list-options-request-error.hook";

const useStyles = makeStyles(() => styles);
const AppV2 = () => {
  useStyles(); // sets global styles for dialog
  const { t } = useTranslation();
  const { isLoadError, setIsLoadError } = useFormListOptionsRequestErrors();
  const { isSaveError, setIsSaveError } = useProfileFormSubmission();

  const handleCloseSnackbar = () => {
    setIsLoadError(false);
    setIsSaveError(false);
  };

  return (
    <Fragment>
      <Dialog
        body={<ProfileForm />}
        footer={<SubmitButtonV2 />}
        maxDialogWidth="699px"
        open={true}
        rootClassName="gx-profile-v2-dialog"
        title={t("Tell us about yourself")}
        variant="overlay"
      />
      <SnackbarBanner
        isDefaultErrorMessage={true}
        isOpen={isSaveError || isLoadError}
        setIsOpen={handleCloseSnackbar}
        type="ERROR"
      />
    </Fragment>
  );
};

export default AppV2;
