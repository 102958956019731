import { colorPalette, weightPalette } from "gx-npm-common-styles";

const profileFormStyles = {
  formContainer: {
    marginTop: "28px",
    "& .gx-profile-input": {
      width: "290px",
    },
    "& .gx-profile-select-paper-popover": {
      width: "290px",
    },
  },
  formRow: {
    display: "flex",
    marginBottom: 24,
    width: "604px",
    "& .gx-second-browsing-button, & .gx-profile-input:nth-child(2)": {
      marginLeft: "auto",
      marginRight: "0",
    },
  },
  subtitle: {
    color: colorPalette.neutrals.coal.hex,
    fontSize: "16px",
    fontVariationSettings: weightPalette.regular.variation,
    fontWeight: weightPalette.regular.amount,
    letterSpacing: "0.25px",
    lineHeight: "24px",
    marginBottom: "24px",
  },
  cardsSubtitle: {
    marginBottom: "12px",
    marginTop: "36px",
  },
};

export default profileFormStyles;
