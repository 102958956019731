import * as singleSpa from "single-spa";
import redirectURLFromWindowLocation from "./redirect-url-from-window-location.lib";

const closeProfileWithRedirectTransition = () => {
  attemptToAddClosingTransitionCloseToDialogRoot();
  setTimeout(() => {
    singleSpa.navigateToUrl(redirectURLFromWindowLocation());
  }, 350);
};

const attemptToAddClosingTransitionCloseToDialogRoot = () => {
  try {
    document.querySelector(".Profile-App-MuiDialog-root").classList.add("gx-closing");
  } catch (_ignored) {
    // do nothing
  }
};

export default closeProfileWithRedirectTransition;
